import { ModalProps } from 'antd';
import CancelCastingModal from './CancelCastingModal';
import InviteCastingModal from './InviteCastingModal';
import MediaGalleryModal from './MediaGalleryModal';
import PromptEmailVerificationModal from './PromptEmailVerificationModal';
import TalentWithdrawApplicationModal from './TalentWithdrawApplicationModal';
import UpdateApplicantStatusModal from './UpdateApplicantStatusModal';
import DeleteHirerUserModal from './DeleteHirerUserModal';
import DeleteProjectModal from './DeleteProjectModal';
import DeleteRoleModal from './DeleteRoleModal';
import PromptHirerRateTalentModal from './PromptHirerRateTalentModal';
import DeleteCommentModal from './DeleteCommentModal';
import RatingCommentModal from './RatingCommentModal';
import TalentRatingDescModal from './TalentRatingDescModal';
import PromptConfirmDuitNowModal from './PromptConfirmDuitNowModal';
import ChangePaymentMethodModal from './ChangePaymentMethodModal';
import PromptSubscribeWhatsappModal from './PromptSubscribeWhatsappModal';
import ApplyOtherStateJobModal from './ApplyOtherStateJobModal';

const modals = {
  'apply-other-state-job': ApplyOtherStateJobModal,
  'media-gallery': MediaGalleryModal,
  'update-applicant-status': UpdateApplicantStatusModal,
  'onboard-prompt-email-verification': PromptEmailVerificationModal,
  'delete-hirer-user': DeleteHirerUserModal,
  'invite-casting': InviteCastingModal,
  'talent-withdraw-application': TalentWithdrawApplicationModal,
  'cancel-casting': CancelCastingModal,
  'delete-project': DeleteProjectModal,
  'delete-role': DeleteRoleModal,
  'prompt-hirer-rate-talent': PromptHirerRateTalentModal,
  'delete-comment': DeleteCommentModal,
  'talent-rating-comment': RatingCommentModal,
  'talent-rating-desc': TalentRatingDescModal,
  'prompt-confirm-duitnow': PromptConfirmDuitNowModal,
  'change-payment-method': ChangePaymentMethodModal,
  'prompt-subscribe-whatsapp': PromptSubscribeWhatsappModal,
};

export type ModalName = keyof typeof modals;

type Primitive = string | number | boolean | null | undefined;
export interface ModalConfig {
  modalProps?: {
    [K in keyof ModalProps]: ModalProps[K] extends Primitive
      ? ModalProps[K]
      : never;
  };
  context?: Record<string, unknown>;
}

export default modals;
