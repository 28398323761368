import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message, Spin, Typography } from 'antd';
import { Div } from '../../framework';
import { RootState } from '../../store/store';
import { closeModal, setForceDisplayPrompt } from '../../store/app.slice';
import { useJobService } from '../../service/job.service';
import { addApplication } from '../../store/talent.slice';
import { Project, Role } from '../../helper/type';
import { calcRolePayment } from '../../helper/job/calcRolePayment';

export type ApplyOtherStateJobModalContext = {
  project: Project;
  role: Role;
};

const { Title, Link } = Typography;

const ApplyOtherStateJobModal: FC = () => {
  const dispatch = useDispatch();
  const jobService = useJobService();
  const { modal, isPWA, loadStack } = useSelector(
    (state: RootState) => state.app
  );
  const { talent } = useSelector((state: RootState) => state.user);
  const { project, role } = modal.config
    .context as ApplyOtherStateJobModalContext;

  const [showRatePrompt, setShowRatePrompt] = useState(false);
  const isApplying = loadStack.includes(`job/apply/${role.id}`);

  const promptInstallPWA = () => {
    const content = (
      <>
        <Link href='/talent/prompt-install'>Install our app</Link> to get
        real-time notifications! 😎🔥 Don&apos;t miss out on amazing
        opportunities tailored just for you! 😉
      </>
    );

    message.warn(content, 15);
  };

  const confirmApplyJob = async () => {
    if (isApplying) return;

    await jobService.applyJob({
      roleId: role.id,
      agreeStatedRate: true,
    });
    const { id: roleId, payment } = role;
    const { id: talentId, ...talentWithoutId } = talent;
    dispatch(
      addApplication({
        ...talentWithoutId,
        role,
        project,
        rolePayment: payment,
        appliedAt: new Date().toISOString(),
        talentId: talentId as string,
        roleId: roleId,
        agreeStatedRate: true,
        agreeStatedRateTimestamp: new Date().toISOString(),
      })
    );

    if (!isPWA) {
      dispatch(setForceDisplayPrompt({ isForceDisplay: true }));
      promptInstallPWA();
    }

    dispatch(closeModal());
  };

  if (!showRatePrompt) {
    return (
      <TravelAtOwnCostPrompt
        project={project}
        handleYes={() => setShowRatePrompt(true)}
        handleNo={() => dispatch(closeModal())}
      />
    );
  }

  return (
    <AcceptStatedRatePrompt
      role={role}
      isApplying={isApplying}
      handleYes={confirmApplyJob}
      handleNo={() => dispatch(closeModal())}
    />
  );
};

type TravelAtOwnCostPromptProps = {
  project: Project;
  handleYes: () => void;
  handleNo: () => void;
};
const TravelAtOwnCostPrompt = ({
  project,
  handleYes,
  handleNo,
}: TravelAtOwnCostPromptProps) => {
  return (
    <Div>
      <Title level={4} style={{ textAlign: 'center' }}>
        Confirm apply job in other state?
      </Title>
      <Div mb='l'>
        This job is based in{' '}
        <span style={{ fontWeight: 600 }}>({project.state})</span>. Are you
        prepared to travel at your own cost?
      </Div>
      <Div>
        <Button
          block
          type='primary'
          onClick={handleYes}
          style={{ marginBottom: '0.75rem' }}
        >
          Yes, I will travel at my own cost
        </Button>
      </Div>
      <Button block onClick={handleNo}>
        No, I won&apos;t
      </Button>
    </Div>
  );
};

type AcceptStatedRatePromptProps = {
  role: Role;
  isApplying: boolean;
  handleYes: () => void;
  handleNo: () => void;
};
const AcceptStatedRatePrompt = ({
  role,
  isApplying,
  handleYes,
  handleNo,
}: AcceptStatedRatePromptProps) => {
  const [disableYes, setDisableYes] = useState(true);
  const [remainingSec, setRemainingSec] = useState(5);

  const countdown = (sec: number) => {
    if (sec <= 0) return;

    setRemainingSec(sec - 1);
    setTimeout(() => countdown(sec - 1), 1000);
  };
  useEffect(() => {
    countdown(6);

    setTimeout(() => {
      setDisableYes(false);
    }, 5000);
  }, []);

  return (
    <Div>
      <Title level={4} style={{ textAlign: 'center' }}>
        Confirm apply job in other state?
      </Title>
      <Div mb='l'>
        If chosen, will you accept the shooting at the{' '}
        <span style={{ fontWeight: 600 }}>
          stated rate (RM {calcRolePayment(role.payment)})
        </span>{' '}
        without negotiation?
      </Div>
      <Div mb='m'>
        <Spin spinning={isApplying} size='small'>
          <Button
            block
            type='primary'
            onClick={handleYes}
            disabled={disableYes}
          >
            {remainingSec ? remainingSec : null} Yes, I accept
          </Button>
        </Spin>
      </Div>
      <Button block onClick={handleNo}>
        No
      </Button>
    </Div>
  );
};

export default ApplyOtherStateJobModal;
