import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, message, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Div } from '../../framework';
import PhoneNumberInput, {
  phoneNumberInputValidator,
  PhoneNumberInputValue,
} from '../PhoneNumberInput/PhoneNumberInput';
import { openWaChat } from '../../helper/message';
import { useSettingsService } from '../../service/settings.service';
import { setPartialSettings } from '../../store/user.slice';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';

const { Title, Paragraph } = Typography;

type ModalContext = {
  projectId: string;
};

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.startsWith('0') ? phoneNumber.substring(1) : phoneNumber;
};

const PromptSubscribeWhatsappModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { talent } = useSelector((state: RootState) => state.user);
  const { modal } = useSelector((state: RootState) => state.app);
  const settingsService = useSettingsService();
  const { projectId } = (modal.config.context ?? {}) as ModalContext;

  const [editPhoneNumber, setEditPhoneNumber] = useState(false);
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState(
    talent.phoneNumberPrefix
  );
  const [phoneNumber, setPhoneNumber] = useState(
    formatPhoneNumber(talent.phoneNumber || '')
  );
  const [promptRefresh, setPromptRefresh] = useState(false);

  const handleSubscribeWhatsapp = async () => {
    if (!phoneNumberPrefix || !phoneNumber) return;

    const validPhoneNumber = formatPhoneNumber(phoneNumber);

    await settingsService.whatsappActivateRequest({
      phoneNumberPrefix,
      phoneNumber: validPhoneNumber,
      loopback: `/p/${projectId}`,
    });

    dispatch(
      setPartialSettings({
        isWhatsappNotificationOn: false,
        isWhatsappVerified: false,
        whatsappPhoneNumber: validPhoneNumber,
        whatsappPhoneNumberPrefix: phoneNumberPrefix,
      })
    );

    setPromptRefresh(true);
  };

  const handleSaveEdit = (values: { phoneNumber: PhoneNumberInputValue }) => {
    const { phoneNumberPrefix, phoneNumber } = values.phoneNumber;
    setPhoneNumberPrefix(phoneNumberPrefix);
    setPhoneNumber(formatPhoneNumber(phoneNumber));
    setEditPhoneNumber(false);
  };

  if (promptRefresh) {
    return (
      <Div className='prompt-subscribe-whatsapp-modal'>
        <Title level={4}>Refresh page to get latest verification status</Title>
        <Div mb='l'>
          We have sent a verification link to your{' '}
          <a href={openWaChat()} target='_blank' rel='noreferrer'>
            WhatsApp
          </a>
          , please click on the link to activate WhatsApp notification.
        </Div>
        <Div mb='l'>
          Once you&apos;ve completed the verification,{' '}
          <span style={{ fontWeight: 600 }}>
            kindly refresh the page to update your verification status.
          </span>
        </Div>
        <Div mb='m' mt='xxl'>
          <Button block type='primary' onClick={() => window.location.reload()}>
            Refresh page
          </Button>
        </Div>
      </Div>
    );
  }

  return (
    <Div className='prompt-subscribe-whatsapp-modal'>
      <Title level={4}>Receive real-time updates via WhatsApp</Title>
      <Div mb='l'>
        Hi {talent.name}! Before you can apply for this awesome job, we just
        need one quick thing from you: please verify your WhatsApp number 📲💬
      </Div>
      <Div mb='l'>✨ Why verify?</Div>
      <Div style={{ paddingLeft: '16px' }}>
        <Paragraph>1️⃣ Get real-time updates on job applications</Paragraph>
        <Paragraph>
          2️⃣ Receive important casting details right to your phone
        </Paragraph>
        <Paragraph>3️⃣ Never miss an exciting opportunity again!</Paragraph>
      </Div>
      <Div mb='l'>
        🔔 Let&apos;s make your journey smoother and more connected! 😊
      </Div>

      <Paragraph>
        To ensure a smooth process when binding to WhatsApp, please confirm if
        this mobile number is reachable via WhatsApp.
        <br />
        {!editPhoneNumber && (
          <>
            <span style={{ fontWeight: 600, fontSize: 'large' }}>
              {`${phoneNumberPrefix}${phoneNumber}`.replace(
                /(\d{1})(\d{3})(\d{3,4})(\d{4})/,
                '$1 $2 $3 $4'
              )}
            </span>
            <Button
              type='link'
              icon={<EditOutlined />}
              onClick={() => setEditPhoneNumber(true)}
            >
              Edit
            </Button>
          </>
        )}
      </Paragraph>

      {editPhoneNumber && (
        <Form onFinish={handleSaveEdit}>
          <Form.Item
            name='phoneNumber'
            rules={[
              { required: true },
              {
                validator: phoneNumberInputValidator({
                  emptyError:
                    'Please input a mobile number that is reachable via WhatsApp',
                }),
              },
            ]}
            initialValue={{ phoneNumberPrefix, phoneNumber }}
            style={{ marginBottom: '0.5rem' }}
          >
            <PhoneNumberInput />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              block
              icon={<EditOutlined />}
              htmlType='submit'
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}

      <Div mb='m' mt='xxl'>
        <Button
          block
          type='primary'
          onClick={handleSubscribeWhatsapp}
          disabled={editPhoneNumber}
        >
          Verify WhatsApp Number
        </Button>
      </Div>
    </Div>
  );
};

export default PromptSubscribeWhatsappModal;
