import React, { FC } from 'react';
import { Typography } from 'antd';
import { Div } from '../../framework';

const { Title, Paragraph } = Typography;

const TalentRatingDescModal: FC = () => {
  return (
    <Div>
      <Title level={3} style={{ textAlign: 'center' }}>
        How ratings work
      </Title>
      <Paragraph>
        <Div style={{ fontWeight: 600 }}>Who can rate me?</Div>
        <Div>
          Only Hirers who have completed a project with you can provide a rating
          and respond to comments related to that rating.
        </Div>
      </Paragraph>
      <Paragraph>
        <Div style={{ fontWeight: 600 }}>Who can view my ratings?</Div>
        <Div>
          Your rating is only visible to logged-in Hirers on Heycast.me. These
          ratings will not be publicly accessible.
        </Div>
      </Paragraph>
      <Paragraph>
        <Div style={{ fontWeight: 600 }}>What do the stars represent?</Div>
        <Div>
          Ratings page: Ratings from your past Hirers.
          <br />
          Profile page: Average rating per criterion, based on total ratings
          received.
        </Div>
      </Paragraph>
    </Div>
  );
};

export default TalentRatingDescModal;
